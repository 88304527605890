/* ----button xem giấy cnbh---- */
.btn {
    line-height: 50px;
    height: 40px;
    text-align: center;
    width: 115px;
    cursor: pointer;
    /* border-radius: 15px; */
  }
.btn-glow {
    background-color: #e67300;
    color: #fff;
    border: 2px solid transparent;
    padding: 8px 16px;
    font-size: 14px;
    transition: all 0.3s ease;
    border-radius: 4px;
}

.btn-glow:hover {
    border-radius: 4px;
    border-color: #fff;
    background-color: transparent;
    color: #e67300;
}

/* ----button xem chi tiet---- */
.glow-on-hover {
    background-color: #1e8f50; /* Màu nền gốc */
    color: white;
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 4px;
    transition: all 0.3s ease; /* Hiệu ứng chuyển đổi mượt mà */
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Đổ bóng nhẹ ban đầu */
    cursor: pointer;
}

.glow-on-hover:hover {
    background-color: #166f3d; /* Màu tối hơn khi hover */
    box-shadow: 0 8px 15px rgba(30, 143, 80, 0.4); /* Thêm ánh sáng */
    transform: scale(1.05); /* Phóng to nhẹ */
}

.glow-on-hover:active {
    transform: scale(0.98); /* Thu nhỏ khi bấm */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* ----modal chi tiet---- */
.ant-modal {
    width: 1050px !important; 
    border-radius: 15px !important;
    /* max-height: 80vh;  */
    /* overflow-y: auto;  */
}

.ant-modal::-webkit-scrollbar {
    display: none; /* Ẩn scrollbar */
}

.ant-modal-title {
    padding-top:5px !important;
}


/* Mặc định: không cuộn, dùng overflow hidden */
.ant-modal-wrap {
    overflow: hidden !important;
    display: flex;
}

/* Cho màn hình nhỏ: cho phép cuộn */
@media (max-width: 1600px) {
    .ant-modal-wrap {
        overflow: auto !important; /* Bật cuộn */
        height: 100%; /* Chiều cao tự điều chỉnh theo nội dung */
        align-items: flex-start; /* Căn Modal ở trên cùng khi cuộn */
        
    }
    .ant-modal {
        margin-bottom: 50px;
    }
}

/* .ant-modal-close-x {
    padding-top:20px !important;
    padding-right:30px !important;
} */
  
.xbc-on-hover:hover {
    background-color: #1e447e;
    color: #ffffff;            
}

.mdl-js {
    overflow: hidden !important;
    height: 100%;
}

.dir-ltr {
    background-color: rgba(189, 51, 51, 0);
    font-family: Arial, sans-serif;
    overflow:auto  !important;
}

.button-light {
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0;
}
.button-light:hover,
    .button-light:focus {
        animation: pulse 1s infinite;
        transition: .3s;
    }
    @keyframes pulse {
        0% {
          transform: scale(1);
        }
        70% {
          transform: scale(.9);
        }
          100% {
          transform: scale(1);
        }
      }
      

.button-dark {
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.button-dark:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.button-dark:active:after {
    background: transparent;
}

.button-dark:hover:before {
    opacity: 1;
}

.button-dark:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

/* Nút "Trang trước" khi ở theme sáng */
.button-prev-light {
    background-color: #4A81F8;
    border: 1px solid #4A81F8;
    color: #ffffff;
    transition: all 0.3s ease;
  }
  
  .button-prev-light:hover {
    background-color: #f5f5f5; /* Nền sáng hơn khi hover */
    border-color: #bbbbbb;
    color: #000000;
  }
  
  /* Nút "Trang trước" khi ở theme tối */
  .button-prev-dark {
    background-color: #598af5;
    border: 1px solid #90b3ff;
    color: #ffffff;
    transition: all 0.3s ease;
  }
  
  /* Nút "Trang sau" khi ở theme sáng */
  .button-next-light {
    background-color: #4A81F8;
    border: 1px solid #4A81F8;
    color: #ffffff;
    transition: all 0.3s ease;
  }
  
  .button-next-light:hover {
    background-color: #4A81F8; /* Nền sáng hơn khi hover */
    border-color: #bbbbbb;
    color: #ffffff;
  }
  
  /* Nút "Trang sau" khi ở theme tối */
  .button-next-dark {
    background-color: #598af5;
    border: 1px solid #90b3ff;
    color: #ffffff;
    transition: all 0.3s ease;
  }
  
  /* Trạng thái disabled (áp dụng cho cả nút ở theme sáng và tối) */
  .button-prev-light:disabled,
  .button-prev-dark:disabled,
  .button-next-light:disabled,
  .button-next-dark:disabled {
    background-color: #ffffff; /* Màu nền xám nhạt */
    border: 1px solid #dbdada;
    color: #a1a1a1; /* Màu chữ xám mờ */
    cursor: not-allowed; /* Con trỏ hiển thị không được phép click */
    opacity: 0.7; /* Nhẹ nhàng làm mờ */
  }

:where(.css-dev-only-do-not-override-fdzv7j).ant-menu-dark, :where(.css-dev-only-do-not-override-fdzv7j).ant-menu-dark>.ant-menu {
    background: #283142 !important;
}

:where(.css-dev-only-do-not-override-fdzv7j).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, :where(.css-dev-only-do-not-override-fdzv7j).ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #283142 !important;
}

:where(.css-fdzv7j).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, :where(.css-fdzv7j).ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #283142;
}
:where(.css-fdzv7j).ant-menu-dark, :where(.css-fdzv7j).ant-menu-dark>.ant-menu {
    background: #283142 !important;
}

:where(.css-fdzv7j).ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline, :where(.css-fdzv7j).ant-menu-dark>.ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #283142 !important;
}

:where(.css-dev-only-do-not-override-fdzv7j).ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-fdzv7j).ant-menu-dark>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #fff;
}
:where(.css-dev-only-do-not-override-fdzv7j).ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title, :where(.css-dev-only-do-not-override-fdzv7j).ant-menu-light>.ant-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #3e79f7;
}

.ant-menu-item-active,
.ant-menu-item-selected,
[data-theme="light"] .ant-menu-submenu-title-active {
    color: #3e79f7;
  }
  
  [data-theme="light"] .ant-menu-submenu-open > .ant-menu-submenu-title {
    color: #3e79f7; 
  }
  
  [data-theme="dark"] .ant-menu-submenu-title-active {
    color: #ffffff; 
  }
  
  [data-theme="dark"] .ant-menu-submenu-open > .ant-menu-submenu-title {
    color: #ffffff;
  }